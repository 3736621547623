.logout_btn{
display: none;
float: right;
color: 'white';
background-color: rgba(255,255,255,0);
border: none;
}
#logout_btn2{
    color: white;
}

@media only screen and (max-width: 800px) {
    .logout_btn{
        display: inline;
    }
  }